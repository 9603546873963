
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    sound: Object
  },
  methods: {
    playSound () {
      this.sound.audio.play()
    }
  }
})

export default class Sound extends Vue {}
