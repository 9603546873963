
import { Options, Vue } from 'vue-class-component'
import Sound from './components/Sound.vue'

@Options({
  components: {
    Sound
  },
  data () {
    return {
      sounds: [
        {
          title: 'Apologise',
          audio: new Audio('/audio/apologise.m4a'),
          file: '/audio/apologise.m4a'
        },
        {
          title: 'Bad boy, good boy',
          audio: new Audio('/audio/bad-boy-good-boy.m4a'),
          file: '/audio/bad-boy-good-boy.m4a'
        },
        {
          title: 'You fucking bastard!',
          audio: new Audio('/audio/bastard.m4a'),
          file: '/audio/bastard.m4a'
        },
        {
          title: 'Brainfart?',
          audio: new Audio('/audio/brainfart.m4a'),
          file: '/audio/brainfart.m4a'
        },
        {
          title: 'Championship hopes',
          audio: new Audio('/audio/championship-hopes.m4a'),
          file: '/audio/championship-hopes.m4a'
        },
        {
          title: 'Dickhead!',
          audio: new Audio('/audio/dickhead.m4a'),
          file: '/audio/dickhead.m4a'
        },
        {
          title: 'Disqualified!',
          audio: new Audio('/audio/disqualified.m4a'),
          file: '/audio/disqualified.m4a'
        },
        {
          title: 'Fuck off, Linus',
          audio: new Audio('/audio/fuck-off-linus.m4a'),
          file: '/audio/fuck-off-linus.m4a'
        },
        {
          title: 'Fuck you',
          audio: new Audio('/audio/fuck-you.m4a'),
          file: '/audio/fuck-you.m4a'
        },
        {
          title: 'Fuck you, Linus',
          audio: new Audio('/audio/fuck-you-linus.m4a'),
          file: '/audio/fuck-you-linus.m4a'
        },
        {
          title: 'Fucking hell!',
          audio: new Audio('/audio/fucking-hell.m4a'),
          file: '/audio/fucking-hell.m4a'
        },
        {
          title: 'Fucking hell 2',
          audio: new Audio('/audio/fucking-hell-2.m4a'),
          file: '/audio/fucking-hell-2.m4a'
        },
        {
          title: 'Get off the track',
          audio: new Audio('/audio/get-off-the-track.m4a'),
          file: '/audio/get-off-the-track.m4a'
        },
        {
          title: 'Get out me fucking road',
          audio: new Audio('/audio/get-out-me-fucking-road.m4a'),
          file: '/audio/get-out-me-fucking-road.m4a'
        },
        {
          title: 'Heat in my tyres',
          audio: new Audio('/audio/heat-in-tyres.m4a'),
          file: '/audio/heat-in-tyres.m4a'
        },
        {
          title: 'Jesus!',
          audio: new Audio('/audio/jesus.m4a'),
          file: '/audio/jesus.m4a'
        },
        {
          title: 'Let me through!',
          audio: new Audio('/audio/just-let-me-through.m4a'),
          file: '/audio/just-let-me-through.m4a'
        },
        {
          title: 'Like you ever do that',
          audio: new Audio('/audio/like-you-ever-do-that.m4a'),
          file: '/audio/like-you-ever-do-that.m4a'
        },
        {
          title: 'Up me chuff',
          audio: new Audio('/audio/linus-up-me-chuff.m4a'),
          file: '/audio/linus-up-me-chuff.m4a'
        },
        {
          title: 'Losing concentration',
          audio: new Audio('/audio/losing-concentration.m4a'),
          file: '/audio/losing-concentration.m4a'
        },
        {
          title: 'Move!',
          audio: new Audio('/audio/move.m4a'),
          file: '/audio/move.m4a'
        },
        {
          title: 'No, Linus, No',
          audio: new Audio('/audio/no-linus-no.m4a'),
          file: '/audio/no-linus-no.m4a'
        },
        {
          title: 'No, you cunt!',
          audio: new Audio('/audio/no-you-cunt.m4a'),
          file: '/audio/no-you-cunt.m4a'
        },
        {
          title: 'Not ready...',
          audio: new Audio('/audio/not-ready.m4a'),
          file: '/audio/not-ready.m4a'
        },
        {
          title: 'Off the road',
          audio: new Audio('/audio/off-the-road.m4a'),
          file: '/audio/off-the-road.m4a'
        },
        {
          title: 'Oh shit!',
          audio: new Audio('/audio/oh-shit.m4a'),
          file: '/audio/oh-shit.m4a'
        },
        {
          title: 'Oi!',
          audio: new Audio('/audio/oi.m4a'),
          file: '/audio/oi.m4a'
        },
        {
          title: 'OMG Fuck this',
          audio: new Audio('/audio/omg-fuck-this.m4a'),
          file: '/audio/omg-fuck-this.m4a'
        },
        {
          title: 'Panting',
          audio: new Audio('/audio/panting.m4a'),
          file: '/audio/panting.m4a'
        },
        {
          title: 'Regret',
          audio: new Audio('/audio/regret.m4a'),
          file: '/audio/regret.m4a'
        },
        {
          title: 'Remember Spa',
          audio: new Audio('/audio/remember-spa.m4a'),
          file: '/audio/remember-spa.m4a'
        },
        {
          title: 'Shitbox power',
          audio: new Audio('/audio/shitbox-power.m4a'),
          file: '/audio/shitbox-power.m4a'
        },
        {
          title: 'Smoking hot',
          audio: new Audio('/audio/smoking-hot.m4a'),
          file: '/audio/smoking-hot.m4a'
        },
        {
          title: 'Thank you, Linus!',
          audio: new Audio('/audio/thank-you-linus.m4a'),
          file: '/audio/thank-you-linus.m4a'
        },
        {
          title: 'Up the ass!',
          audio: new Audio('/audio/up-the-ass.m4a'),
          file: '/audio/up-the-ass.m4a'
        },
        {
          title: 'Up the ass 2',
          audio: new Audio('/audio/up-the-ass-2.m4a'),
          file: '/audio/up-the-ass-2.m4a'
        },
        {
          title: 'Verstappen',
          audio: new Audio('/audio/verstappen.m4a'),
          file: '/audio/verstappen.m4a'
        },
        {
          title: 'Warn you!',
          audio: new Audio('/audio/warn-you.m4a'),
          file: '/audio/warn-you.m4a'
        },
        {
          title: 'Watch it!',
          audio: new Audio('/audio/watch-it.m4a'),
          file: '/audio/watch-it.m4a'
        },
        {
          title: 'Watch it 2',
          audio: new Audio('/audio/watch-it-2.m4a'),
          file: '/audio/watch-it-2.m4a'
        }
      ]
    }
  }
})
export default class App extends Vue {}
